<template>
<div>
  <head-layout head-title="数据血缘新增" />
  <el-card class="box-card">
    <el-form ref="form" :model="form" label-width="80px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="编号">
            <el-input v-model="form.id" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="表名">
            <el-input v-model="form.text"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="备注">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="节点名称">
            <el-input v-model="form.nodeName"></el-input>
          </el-form-item>
        </el-col>

      </el-row>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button>取消</el-button>
      </el-form-item>

    </el-form>

  </el-card>
</div>
</template>

<script>
export default {
  name: "PrimordialAdd",
  mounted() {
    this.getTableData()
  },
  data(){
    return{
      form: {
        id: '',
        remark: '',
        text: '',
        nodeName: '',

      },
      tableData:[]
    }
  },
  methods:{
    getTableData(){
      this.tableData=JSON.parse(localStorage.getItem('PRIMODDIAL-TABLE'))
      this.form.id='N'+(this.tableData.length+1)

    },
    onSubmit(){
      this.tableData.push(this.form)
    }

  }
}
</script>

<style scoped>
.box-card {
  height: 100vh;
}
</style>
